import React, { Component, Suspense } from "react";
import { insertData } from "../components/config/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  Alert,
  Col,
  Container,
  Row,
} from "reactstrap";
import { multiLanguage } from "../components/Language/getBahasa";
import { withAlert } from "react-alert";
import LoadingOverlay from "react-loading-overlay";
import LogoImage from "../assets/img/brand/nas_logo.png";
// import "../css/All.css";
// import "../css/LSP_Demo.css";
// import "../css/Button.css";
import { Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import {
  Brand_LSP,
  // getLsp,
} from "../components/config/config";

class MultiRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: "",
      hidden: true,
      response: "",
      register_id: "",
      permission: [],
      userdata: [],
      user_permission_id: "",
      hideCopyright: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // submitMulti = (item, i) => {
  //   const data1 = this.state.permission;
  //   data1.map((idx) => {
  //     console.log("idx", idx);
  //     if (idx.is_login == 1) {
  //       idx.is_login = "0";
  //       this.setState({ permission: data1 });
  //     }
  //     data1.find((c) => {
  //       console.log("c", c);
  //       console.log('item',item)
  //       if (
  //         c.user_permission_id === item.user_permission_id &&
  //         item.is_login == 0
  //       ) {
  //         item.is_login = "1";
  //         this.setState({
  //           permission: data1,
  //           user_permission_id: c.user_permission_id,
  //         });
  //       }
  //     });
  //   });
  // };

  submitMulti = (item, i) => {
    this.setState({
      user_permission_id: item.user_permission_id,
    });
  };

  setLocalStorage = (responseJSON) => {
    localStorage.setItem("bahasa", "id");
    localStorage.setItem("secret_key", responseJSON.secret_key);
    let role = responseJSON.data.role_code;
    localStorage.setItem("notif_role",  role);
    if(role=='DIR' || role=='MGR'){
      role='SUP'
    }
    localStorage.setItem("role", role);
    localStorage.setItem(
      "selected_permission",
      JSON.stringify(responseJSON.data.selected_permission)
    );
    localStorage.setItem(
      "ref_id",
      JSON.stringify(responseJSON.data.selected_permission[0].ref_id)
    );
    let arr = [];
    const json = responseJSON.data;

    let selectedPermission = json.selected_permission;
    let isIntegration = false;
    let isLpjkIntegration = false;
    let newListIntegration = [];
    if(selectedPermission && selectedPermission.length > 0){
      let firstSelectedPermission = selectedPermission[0];
      if(firstSelectedPermission){
        localStorage.setItem("logo_lsp", firstSelectedPermission.lsp_logo ? firstSelectedPermission.lsp_logo : firstSelectedPermission.logo);
      }
      let integrationList = firstSelectedPermission.integration_detail;
      if(integrationList && integrationList.length > 0){
        isIntegration = true;
        integrationList.map((val, index) => {
          if(val.system_id=="4f15e5e2-b0ab-41b3-bf18-6d24bbf8d062"){
            isLpjkIntegration = true
          }
          let urlDomain = val.url_domain ? val.url_domain : '';
          let dataIntegration = {
            auth_type:val.auth_type ? val.auth_type : '',
            integration_id: val.integration_id ? val.integration_id : '',
            system_id: val.system_id ? val.system_id : '',
            system_name: val.system_name ? val.system_name : '',
            url: urlDomain,
          }
          let authParameter = val.auth_parameter ? val.auth_parameter : [];
          dataIntegration['auth_parameter'] = authParameter;
          newListIntegration.push(dataIntegration);
        })
      }
    }
    let tukAccess = json.tuk_access;
    if(tukAccess){
      let lsp_id = tukAccess.lsp_id;
      if(lsp_id){
        localStorage.setItem("lsp_id", lsp_id);
      }
    }
    localStorage.setItem("isIntegration", isIntegration);
    localStorage.setItem("isLPJKIntegration", isLpjkIntegration);
    localStorage.setItem('integrationList', JSON.stringify(newListIntegration));
    for (let index = 0; index < json.permission.length; index++) {
      const element = json.permission[index].sub_module_code;
      arr.push(element);
      localStorage.setItem("permission", arr);
    }
  };

  submitContinue = () => {
    this.setState({ loading: true });
    const { history } = this.props;
    const { response } = this.state;
    if (this.state.user_permission_id == "") {
      this.setState({
        hidden: false,
        message: "Belum ada role yang di pilih",
        loading: false,
      });
    } else {
      let formData = new FormData();
      formData.append("user_permission_id", this.state.user_permission_id);
      insertData("/users/select_lsp", formData)
        .then((result) => {
          let responseJSON = result;
          this.setState({ loading: false });
          if (responseJSON.responseStatus === "SUCCESS") {
            if (
              responseJSON.data.role_code === "DEV" ||
              responseJSON.data.role_code === "ADM" ||
              responseJSON.data.role_code === "ACS" ||
              responseJSON.data.role_code === "SUP" ||
              responseJSON.data.role_code === "APL" ||
              responseJSON.data.role_code === "DIR" ||
              responseJSON.data.role_code === "MGR" ||
              responseJSON.data.role_code === "ADT"
            ) {
              this.setLocalStorage(responseJSON);
              history.push("/dashboard");
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          if (error.status === 401) {
            localStorage.clear();
            window.location.replace("/login");
          } else if (error.status === 419) {
            localStorage.clear();
            window.location.replace("/login");
          } else if (error.status == 504) {
            localStorage.clear();
            window.location.replace("/login");
          }
        });
    }
  };

  // submitContinue = (event) => {
  //   event.preventDefault();
  //   const { history } = this.props;
  //   this.setState({ loading: true });

  //   if (this.state.user_permission_id == "") {
  //     this.setState({
  //       loading: false,
  //       hidden: false,
  //       message: multiLanguage.alertInput,
  //     });
  //   }
  //   if (this.state.user_permission_id) {
  //     insertData("/users/select_lsp", {
  //       user_permission_id: this.state.user_permission_id,
  //     }).then((result) => {
  //       this.setState({ loading: false });
  //       let responseJSON = result;
  //       console.log("res", responseJSON);
  //       if (responseJSON.responseStatus === "SUCCESS") {
  //         if (
  //           responseJSON.data.role_code === "DEV" ||
  //           responseJSON.data.role_code === "ADM" ||
  //           responseJSON.data.role_code === "ACS" ||
  //           responseJSON.data.role_code === "SUP" ||
  //           responseJSON.data.role_code === "APL"
  //         ) {
  //           this.setLocalStorage(responseJSON);
  //           history.push("/dashboard");
  //           window.location.reload();
  //         } else {
  //           localStorage.clear();
  //           this.setState({
  //             hidden: false,
  //             message: "You aren't access,Please check again",
  //           });
  //         }
  //         return;
  //       }
  //       this.setState({
  //         response: responseJSON.error.code,
  //       });
  //       switch (this.state.response) {
  //         case 401:
  //           localStorage.clear();
  //           window.location.replace("/login");
  //           break;
  //         case 400:
  //           localStorage.clear();
  //           this.setState({
  //             hidden: false,
  //             message: "Minimum password length is 6",
  //           });
  //           break;

  //         case 422:
  //           localStorage.clear();
  //           this.setState({
  //             hidden: false,
  //             message: responseJSON.error.message,
  //           });
  //           break;

  //         case 419:
  //           NotificationManager.warning(
  //             "Masa trial anda telah habis,Harap menghubungi Admin NAS untuk info lebih lanjut",
  //             "Peringatan",
  //             5000
  //           );
  //           localStorage.clear();
  //           break;

  //         default:
  //           break;
  //       }
  //     });
  //   }
  // };

  componentDidUpdate() {
    window.onpopstate = (e) => {
      localStorage.clear();
      window.location.replace("/login");
    };
  }

  cekDomain = () => {
    let domain = window.location.hostname;
    if(domain === "app.vokasea.id"){
      this.setState({ hideCopyright:true })
    } else if (domain === "areaindonesia.vokasea.id") {
      this.setState({ hideCopyright:true })
    } else if (domain === "oshe.vokasea.id"){
      this.setState({ hideCopyright:true })
    } else if (domain === "nki.vokasea.id"){
      this.setState({ hideCopyright:true })
    }
    let whiteLabel = false;
    if(domain=='ypia.nasonline.id'){
      whiteLabel = true;
    } else if (domain=='nusantara.nasonline.id'){
      whiteLabel = true;
    } else if (domain=='pim.nasonline.id'){
      whiteLabel = true;
    } else if (domain=='lsphki.aplikasisertifikasi.com'){
      whiteLabel = true;
    } else if (domain=='migrasiabi.aplikasisertifikasi.com'){
      whiteLabel = true;
    } else if (domain=='abimigrasi.aplikasisertifikasi.com' || domain === 'lspabi.nasonline.id'){
      whiteLabel = true;
    } else if (domain === "ditekindo.nasonline.id") {
      whiteLabel = true;
    } else if (domain === "lspfotografi.nasonline.id") {
      whiteLabel = true;
    } else if (domain === "lspmsdmui.nasonline.id"){
      whiteLabel = true;
    } else if (domain === "t2k3indonesia.nasonline.id") {
      whiteLabel = true;
    } else if (domain === "app.vokasea.id") {
      whiteLabel = true;
    } else if (domain === "areaindonesia.vokasea.id") {
      whiteLabel = true;
    } else if (domain === "oshe.vokasea.id") {
      whiteLabel = true;
    } else if (domain === "nki.vokasea.id") {
      whiteLabel = true;
    } else if (domain === "k3ibl.nasonline.id"){
      whiteLabel = true;
    } else if (domain === "lsplik.nasonline.id"){
      whiteLabel = true;
    } else if (domain === "lspei.nasonline.id"){
      whiteLabel = true;
    }

    if (whiteLabel){
      localStorage.setItem("whitelabel", true);
    }
  }

  componentDidMount() {
    this.cekDomain();
    const dataPermission = JSON.parse(localStorage.getItem("user_permissions"));
    const dataUser = JSON.parse(localStorage.getItem("userdata"));
    this.setState({
      permission: dataPermission,
      userdata: dataUser,
    });
  }

  render() {
    const { Logo } = Brand_LSP("login");
    if (localStorage.getItem("logged_in") == true) {
      return <Redirect to={"/login"} />;
    }
    return (
      <LoadingOverlay active={this.state.loading} spinner text="Please Wait...">
        <div className="app flex-row align-items-center animated fadeIn backgroundColor">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <Card
                  className="p-4"
                  style={{
                    width: "90%",
                    margin: "auto",
                    height: "600px",
                    borderRadius: 10,
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <h3
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "#636464",
                          }}
                        >
                          {" "}
                          Selamat datang kembali {this.state.userdata.username}.
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6
                          style={{
                            color: "#636464",
                          }}
                        >
                          Anda sudah terdaftar di lebih dari 1 role. Silakan
                          pilih dahulu role yang akan Anda gunakan.
                        </h6>
                      </Col>
                    </Row>

                    <Row>
                      {this.state.permission.map((item, i) => {
                        return (
                          <Col key={i} xs="2" style={{ margin: 12 }}>
                            <Button onClick={() => this.submitMulti(item, i)}>
                              <Card
                                style={{
                                  width: 100,
                                  height: 140,
                                }}
                              >
                                {!this.state.hideCopyright && (
                                  <CardImg
                                      top
                                      style={{
                                        width: 55,
                                        height: 55,
                                        margin: "auto",
                                      }}
                                      src={LogoImage}
                                      alt=""
                                    />
                                )}

                                <CardText
                                  style={{
                                    textAlign: "center",
                                    margin: "auto",
                                  }}
                                >
                                  <div>
                                    <small
                                      className="text-muted"
                                      style={{
                                        fontSize: "10px",
                                        margin: "auto",
                                      }}
                                    >
                                      {item.tuk_name}
                                    </small>
                                  </div>
                                  <small
                                    className="text-muted"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {/* {item.role_code} */}
                                    {item.role_code === "DEV"
                                      ? "Developer"
                                      : item.role_code === "SUP"
                                      ? "Super User"
                                      : item.role_code === "MAG"
                                      ? "Management"
                                      : item.role_code === "ADM"
                                      ? "Admin LSP"
                                      : item.role_code === "ADT"
                                      ? "Admin TUK"
                                      : item.role_code === "ACS"
                                      ? multiLanguage.assessors
                                      : item.role_code === "APL"
                                      ? multiLanguage.asesi
                                      : item.role_code === "DIR"
                                      ? "Ketua LSP"
                                      : item.role_code === "MGR"
                                      ? "Manager LSP"
                                      : ""}
                                  </small>
                                  <br />
                                  <small
                                    className="text-muted"
                                    style={{
                                      fontSize: 8,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.lsp_name}
                                  </small>
                                </CardText>
                              </Card>
                            </Button>
                          </Col>
                        );
                      })}
                    </Row>

                    <Row className="center-block">
                      <Alert
                        color="danger"
                        hidden={this.state.hidden}
                        style={{
                          fontSize: "80%",
                        }}
                      >
                        {this.state.message}
                      </Alert>
                    </Row>

                    {/* <Row style={{ marginTop: "16%" }}> */}
                    <Col
                      style={{
                        bottom: 10,
                        position: "absolute",
                        paddingRight: 100,
                      }}
                    >
                      <Row>
                        <Col>
                          <Button
                            className="btn-login"
                            type="submit"
                            onClick={this.submitContinue}
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                      {!this.state.hideCopyright && (
                        <Row
                          className="footerCopyright"
                          style={{ marginBottom: 20, marginTop: 10 }}
                        >
                          <Col>
                            Copyright &copy; 2021,
                            <a href="https://www.aplikasisertifikasi.com">NAS</a>.
                            All rights reserved
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withAlert(MultiRole);
