import { hasPropertyWithValue } from "./components/config/actions";
import {
  getDataLPJK,
  getIntegration,
  getRole,
  idLSPABI,
  isDemonstrate,
  isLJKIntegration,
  isLSPABI
} from "./components/config/config";

const permission = {};
let dev = {
  en: {
    items: [
      {
        name: "Home",
        url: "/dashboard",
        icon: "icon-home",
      },
      {
        name: "Users",
        url: "/users",
        icon: "icon-people",
      },
      {
        name: "TUK",
        url: "/tuk",
        icon: "icon-note",
      },
      !!isLJKIntegration() && {
        name: "Pemohon SKK",
        url: "/pemohon-skk",
        icon: "fa fa-link",
      },
      {
        name: "Assessors",
        url: "/Assessors",
        icon: "icon-user",
      },
      {
        name: "Participant",
        url: "/asesi",
        icon: "icon-note",
      },
      {
        name: "Skema Sertifikasi",
        url: "/schema/sub-schema",
        icon: "fa icon-grid",
      },
      {
        name: "Assessment",
        icon: "icon-calendar",
        children: [
          {
            name: "Submission",
            url: "/assessments/submission",
            icon: "icon-calendar",
          },
          {
            name: "Rejected",
            url: "/assessments/rejected",
            icon: "icon-calendar",
          },
          {
            name: "List Assessment",
            url: "/assessments/list",
            icon: "icon-calendar",
          },
          {
            name: "Archives",
            url: "/assessments/archives",
            icon: "icon-calendar",
          },
        ],
      },
      // {
      //   name: "Group Chat",
      //   icon: "fa fa-comments",
      //   url: "/chat",
      // },
      {
        name: "Persyaratan",
        icon: "fa icon-layers",
        children: [
          {
            name: "Persyaratan Dasar",
            url: "/portfolios-umum",
            icon: "fa icon-layers",
          },
          {
            name: "Persyaratan Pendaftaran",
            url: "/portfolios",
            icon: "fa icon-layers",
          },
          {
            name: "Materi Uji Kompetensi",
            url: "/materi-uji",
            icon: "fa icon-layers",
          },
          {
            name: "Bukti Administratif",
            url: "/bukti-administratif",
            icon: "fa icon-layers",
          },
        ],
      },
      {
        name: "Riwayat Asesi",
        url: "/alumnis",
        icon: "icon-badge",
      },
      {
        name: "Management Letter",
        url: "/management-letters",
        icon: "icon-folder-alt",
      },
      // {
      //   name: "Log Data",
      //   url: "/restore-data",
      //   icon: "icon-trash",
      // },
      // {
      //   name: "Keluhan Aplikasi",
      //   url: "/complaint",
      //   icon: "fa fa-exclamation-triangle",
      // },
      {
        name: "Konfigurasi Persuratan",
        url: "/letters",
        icon: "fa fa-envelope-open-o",
      },
    ],
  },
  id: {
    items: [
      {
        name: "Beranda",
        url: "/dashboard",
        icon: "icon-home",
      },
      {
        name: "Pengguna",
        url: "/users",
        icon: "icon-people",
      },
      {
        name: "TUK",
        url: "/tuk",
        icon: "icon-pencil",
      },
      !!isLJKIntegration() && {
        name: "Pemohon SKK",
        url: "/pemohon-skk",
        icon: "fa fa-link",
      },
      {
        name: "Asesor",
        url: "/Assessors",
        icon: "icon-user",
      },
      {
        name: "Pemohon",
        url: "/asesi",
        icon: "icon-note",
      },
      {
        name: "Skema Sertifikasi",
        url: "/schema/sub-schema",
        icon: "fa icon-grid",
      },
      {
        name: "Jadwal Asesmen",
        icon: "icon-calendar",
        children: [
          {
            name: "Pengajuan",
            url: "/assessments/submission",
            icon: "icon-calendar",
          },
          {
            name: "Dibatalkan",
            url: "/assessments/rejected",
            icon: "icon-calendar",
          },
          {
            name: "Daftar Asesmen",
            url: "/assessments/list",
            icon: "icon-calendar",
          },
          {
            name: "Pengajuan Banding",
            url: "/assessments/appeal",
            icon: "icon-calendar",
          },
          {
            name: "Arsip",
            url: "/assessments/archives",
            icon: "icon-calendar",
          },
        ],
      },
      // {
      //   name: "Group Chat",
      //   icon: "fa fa-comments",
      //   url: "/chat",
      // },
      {
        name: "Persyaratan",
        icon: "fa icon-layers",
        children: [
          {
            name: "Persyaratan Dasar",
            url: "/portfolios-umum",
            icon: "fa icon-layers",
          },
          {
            name: "Persyaratan Pendaftaran",
            url: "/portfolios",
            icon: "fa icon-layers",
          },
          {
            name: "Materi Uji Kompetensi",
            url: "/materi-uji",
            icon: "fa icon-layers",
          },
          {
            name: "Bukti Administratif",
            url: "/bukti-administratif",
            icon: "fa icon-layers",
          },
        ],
      },
      {
        name: "Riwayat Asesi",
        url: "/alumnis",
        icon: "icon-badge",
      },
      {
        name: "Konfigurasi Persuratan",
        url: "/management-letters",
        icon: "icon-folder-alt",
      },
      // {
      //   name: "Log Data",
      //   url: "/restore-data",
      //   icon: "icon-trash",
      // },
      // {
      //   name: "Keluhan Aplikasi",
      //   url: "/complaint",
      //   icon: "fa fa-exclamation-triangle",
      // },
      {
        name: "Konfigurasi Persuratan",
        url: "/letters",
        icon: "fa fa-envelope-open-o",
      },
    ],
  },
};
permission.role = {
  DEV: dev,
  MAG: {
    en: {
      items: [
        {
          name: "Home",
          url: "/dashboard",
          icon: "icon-home",
        },
        {
          name: "Users",
          url: "/users",
          icon: "icon-people",
        },
        {
          name: "TUK",
          url: "/tuk",
          icon: "icon-note",
        },
        !!isLJKIntegration() && {
          name: "Pemohon SKK",
          url: "/pemohon-skk",
          icon: "fa fa-link",
        },
        {
          name: "Assessors",
          url: "/Assessors",
          icon: "icon-user",
        },
        {
          name: "Participant",
          url: "/asesi",
          icon: "icon-note",
        },
        {
          name: "Skema Sertifikasi",
          url: "/schema/sub-schema",
          icon: "fa icon-grid",
        },
        // {
        //   name: "Schema Certification",
        //   icon: "fa icon-grid",
        //   children: [
        //     {
        //       name: "Main Schema",
        //       url: "/schema/main-schema",
        //       icon: "fa icon-grid",
        //     },
        //     {
        //       name: "Sub Schema",
        //       url: "/schema/sub-schema",
        //       icon: "fa fa-th",
        //     },
        //     {
        //       name: "Unit Competention",
        //       url: "/schema/unit-competention",
        //       icon: "fa fa-th"
        //     }
        //   ],
        // },
        {
          name: "Assessment",
          icon: "icon-calendar",
          children: [
            {
              name: "Submission",
              url: "/assessments/submission",
              icon: "icon-calendar",
            },
            {
              name: "Rejected",
              url: "/assessments/rejected",
              icon: "icon-calendar",
            },
            {
              name: "List Assessment",
              url: "/assessments/list",
              icon: "icon-calendar",
            },
            {
              name: "Archives",
              url: "/assessments/archives",
              icon: "icon-calendar",
            },
          ],
        },
        // {
        //   name: "Uji Tertulis",
        //   icon: "icon-pencil",
        //   children: [
        //     {
        //       name: "Bank Soal",
        //       url: "/writen-test",
        //       icon: "fa fa-folder",
        //     },
        //     {
        //       name: "Riwayat Soal",
        //       url: "/question/history",
        //       icon: "icon-pencil",
        //     },
        //   ],
        // },

        {
          name: "Persyaratan",
          icon: "fa icon-layers",
          children: [
            {
              name: "Persyaratan Dasar",
              url: "/portfolios-umum",
              icon: "fa icon-layers",
            },
            {
              name: "Persyaratan Pendaftaran",
              url: "/portfolios",
              icon: "fa icon-layers",
            },
            {
              name: "Materi Uji Kompetensi",
              url: "/materi-uji",
              icon: "fa icon-layers",
            },
            {
              name: "Bukti Administratif",
              url: "/bukti-administratif",
              icon: "fa icon-layers",
            },
          ],
        },
        {
          name: "Riwayat Asesi",
          url: "/alumnis",
          icon: "icon-badge",
        },
        // {
        //   name: "Log Data",
        //   url: "/restore-data",
        //   icon: "icon-trash",
        // },
        // {
        //   name: "Keluhan Aplikasi",
        //   url: "/complaint",
        //   icon: "fa fa-exclamation-triangle",
        // },
        {
          name: "Konfigurasi Persuratan",
          url: "/letters",
          icon: "fa fa-envelope-open-o",
        },
      ],
    },
    id: {
      items: [
        {
          name: "Beranda",
          url: "/dashboard",
          icon: "icon-home",
        },
        // {
        //   name: "Profile",
        //   url: "/profile",
        //   icon: "fa fa-user",
        // },
        {
          name: "Pengguna",
          url: "/users",
          icon: "icon-people",
        },
        {
          name: "TUK",
          url: "/tuk",
          icon: "icon-pencil",
        },
        !!isLJKIntegration() && {
          name: "Pemohon SKK",
          url: "/pemohon-skk",
          icon: "fa fa-link",
        },
        {
          name: "Asesor",
          url: "/Assessors",
          icon: "icon-user",
        },
        {
          name: "Pemohon",
          url: "/asesi",
          icon: "icon-note",
        },
        // {
        //   name: "Skema Sertifikasi",
        //   icon: "fa icon-grid",
        //   children: [
        //     {
        //       name: "Skema Utama",
        //       url: "/schema/main-schema",
        //       icon: "fa icon-grid",
        //     },
        //     {
        //       name: "Sub Skema",
        //       url: "/schema/sub-schema",
        //       icon: "fa fa-th",
        //     },
        //     {
        //       name: "Unit Kompetensi",
        //       url: "/schema/unit-competention",
        //       icon: "fa fa-th"
        //     }
        //   ],
        // },
        {
          name: "Skema Sertifikasi",
          url: "/schema/sub-schema",
          icon: "fa icon-grid",
        },
        {
          name: "Jadwal Asesmen",
          icon: "icon-calendar",
          children: [
            {
              name: "Pengajuan",
              url: "/assessments/submission",
              icon: "icon-calendar",
            },
            {
              name: "Dibatalkan",
              url: "/assessments/rejected",
              icon: "icon-calendar",
            },
            {
              name: "Daftar Asesmen",
              url: "/assessments/list",
              icon: "icon-calendar",
            },
            {
              name: "Pengajuan Banding",
              url: "/assessments/appeal",
              icon: "icon-calendar",
            },
            {
              name: "Arsip",
              url: "/assessments/archives",
              icon: "icon-calendar",
            },
          ],
        },
        // {
        //   name: "Uji Tertulis",
        //   icon: "icon-pencil",
        //   children: [
        //     {
        //       name: "Bank Soal",
        //       url: "/writen-test",
        //       icon: "fa fa-folder",
        //     },
        //     {
        //       name: "Riwayat Soal",
        //       url: "/question/history",
        //       icon: "icon-pencil",
        //     },
        //   ],
        // },
        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat",
        // },

        {
          name: "Persyaratan",
          icon: "fa icon-layers",
          children: [
            {
              name: "Persyaratan Dasar",
              url: "/portfolios-umum",
              icon: "fa icon-layers",
            },
            {
              name: "Persyaratan Pendaftaran",
              url: "/portfolios",
              icon: "fa icon-layers",
            },
            {
              name: "Materi Uji Kompetensi",
              url: "/materi-uji",
              icon: "fa icon-layers",
            },
            {
              name: "Bukti Administratif",
              url: "/bukti-administratif",
              icon: "fa icon-layers",
            },
          ],
        },
        {
          name: "Riwayat Asesi",
          url: "/alumnis",
          icon: "icon-badge",
        },
        // {
        //   name: "Log Data",
        //   url: "/restore-data",
        //   icon: "icon-trash",
        // },
        // {
        //   name: "Keluhan Aplikasi",
        //   url: "/complaint",
        //   icon: "fa fa-exclamation-triangle",
        // },
        {
          name: "Konfigurasi Persuratan",
          url: "/letters",
          icon: "fa fa-envelope-open-o",
        },
      ],
    },
  },
  SUP: {
    en: {
      items: [
        {
          name: "Home",
          url: "/dashboard",
          icon: "icon-home",
        },
        {
          name: "Users",
          url: "/users",
          icon: "icon-people",
        },
        {
          name: "TUK",
          url: "/tuk",
          icon: "icon-note",
        },
        !!isLJKIntegration() && {
          name: "Pemohon SKK",
          url: "/pemohon-skk",
          icon: "fa fa-link",
        },
        {
          name: "Assessors",
          url: "/Assessors",
          icon: "icon-user",
        },
        {
          name: "Participant",
          url: "/asesi",
          icon: "icon-note",
        },
        {
          name: "Skema Sertifikasi",
          url: "/schema/sub-schema",
          icon: "fa icon-grid",
        },
        // {
        //   name: "Schema Certification",
        //   icon: "fa icon-grid",
        //   children: [
        //     {
        //       name: "Main Schema",
        //       url: "/schema/main-schema",
        //       icon: "fa icon-grid",
        //     },
        //     {
        //       name: "Sub Schema",
        //       url: "/schema/sub-schema",
        //       icon: "fa fa-th",
        //     },
        //     {
        //       name: "Unit Competention",
        //       url: "/schema/unit-competention",
        //       icon: "fa fa-th"
        //     }
        //   ],
        // },
        {
          name: "Assessment",
          icon: "icon-calendar",
          children: [
            {
              name: "Submission",
              url: "/assessments/submission",
              icon: "icon-calendar",
            },
            {
              name: "Rejected",
              url: "/assessments/rejected",
              icon: "icon-calendar",
            },
            {
              name: "List Assessment",
              url: "/assessments/list",
              icon: "icon-calendar",
            },
            {
              name: "Archives",
              url: "/assessments/archives",
              icon: "icon-calendar",
            },
          ],
        },
        // {
        //   name: "Uji Tertulis",
        //   icon: "icon-pencil",
        //   children: [
        //     {
        //       name: "Bank Soal",
        //       url: "/writen-test",
        //       icon: "fa fa-folder",
        //     },
        //     {
        //       name: "Riwayat Soal",
        //       url: "/question/history",
        //       icon: "icon-pencil",
        //     },
        //   ],
        // },

        {
          name: "Persyaratan",
          icon: "fa icon-layers",
          children: [
            {
              name: "Persyaratan Dasar",
              url: "/portfolios-umum",
              icon: "fa icon-layers",
            },
            {
              name: "Persyaratan Pendaftaran",
              url: "/portfolios",
              icon: "fa icon-layers",
            },
            {
              name: "Materi Uji Kompetensi",
              url: "/materi-uji",
              icon: "fa icon-layers",
            },
            {
              name: "Bukti Administratif",
              url: "/bukti-administratif",
              icon: "fa icon-layers",
            },
          ],
        },
        {
          name: "Riwayat Asesi",
          url: "/alumnis",
          icon: "icon-badge",
        },
        // {
        //   name: "Log Data",
        //   url: "/restore-data",
        //   icon: "icon-trash",
        // },
        // // {
        //   name: "Keluhan Aplikasi",
        //   url: "/complaint",
        //   icon: "fa fa-exclamation-triangle",
        // },
        {
          name: "Konfigurasi Persuratan",
          url: "/letters",
          icon: "fa fa-envelope-open-o",
        },
      ],
    },
    id: {
      items: [
        {
          name: "Beranda",
          url: "/dashboard",
          icon: "icon-home",
        },
        // {
        //   name: "Profile",
        //   url: "/profile",
        //   icon: "fa fa-user",
        // },
        {
          name: "Pengguna",
          url: "/users",
          icon: "icon-people",
        },
        {
          name: "TUK",
          url: "/tuk",
          icon: "icon-note",
        },
        !!isLJKIntegration() && {
          name: "Pemohon SKK",
          url: "/pemohon-skk",
          icon: "fa fa-link",
        },
        {
          name: "Asesor",
          url: "/Assessors",
          icon: "icon-user",
        },
        {
          name: "Pemohon",
          url: "/asesi",
          icon: "icon-note",
        },
        // {
        //   name: "Skema Sertifikasi",
        //   icon: "fa icon-grid",
        //   children: [
        //     {
        //       name: "Skema Utama",
        //       url: "/schema/main-schema",
        //       icon: "fa icon-grid",
        //     },
        //     {
        //       name: "Sub Skema",
        //       url: "/schema/sub-schema",
        //       icon: "fa fa-th",
        //     },
        //     {
        //       name: "Unit Kompetensi",
        //       url: "/schema/unit-competention",
        //       icon: "fa fa-th"
        //     }
        //   ],
        // },
        {
          name: "Skema Sertifikasi",
          url: "/schema/sub-schema",
          icon: "fa icon-grid",
        },
        {
          name: "Jadwal Asesmen",
          icon: "icon-calendar",
          children: [
            {
              name: "Pengajuan",
              url: "/assessments/submission",
              icon: "icon-calendar",
            },
            {
              name: "Dibatalkan",
              url: "/assessments/rejected",
              icon: "icon-calendar",
            },
            {
              name: "Daftar Asesmen",
              url: "/assessments/list",
              icon: "icon-calendar",
            },
            {
              name: "Pengajuan Banding",
              url: "/assessments/appeal",
              icon: "icon-calendar",
            },
            {
              name: "Arsip",
              url: "/assessments/archives",
              icon: "icon-calendar",
            },
          ],
        },
        // {
        //   name: "Uji Tertulis",
        //   icon: "icon-pencil",
        //   children: [
        //     {
        //       name: "Bank Soal",
        //       url: "/writen-test",
        //       icon: "fa fa-folder",
        //     },
        //     {
        //       name: "Riwayat Soal",
        //       url: "/question/history",
        //       icon: "icon-pencil",
        //     },
        //   ],
        // },

        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat",
        // },

        {
          name: "Persyaratan",
          icon: "fa icon-layers",
          children: [
            {
              name: "Persyaratan Dasar",
              url: "/portfolios-umum",
              icon: "fa icon-layers",
            },
            {
              name: "Persyaratan Pendaftaran",
              url: "/portfolios",
              icon: "fa icon-layers",
            },
            {
              name: "Materi Uji Kompetensi",
              url: "/materi-uji",
              icon: "fa icon-layers",
            },
            {
              name: "Bukti Administratif",
              url: "/bukti-administratif",
              icon: "fa icon-layers",
            },
          ],
        },
        {
          name: "Riwayat Asesi",
          url: "/alumnis",
          icon: "icon-badge",
        },
        // {
        //   name: "Log Data",
        //   url: "/restore-data",
        //   icon: "icon-trash",
        // },
        // {
        //   name: "Keluhan Aplikasi",
        //   url: "/complaint",
        //   icon: "fa fa-exclamation-triangle",
        // },
        {
          name: "Konfigurasi Persuratan",
          url: "/letters",
          icon: "fa fa-envelope-open-o",
        },
      ],
    },
  },
  ADM: {
    en: {
      items: [
        {
          name: "Home",
          url: "/dashboard",
          icon: "icon-home",
        },
        {
          name: "Users",
          url: "/users",
          icon: "icon-people",
        },
        {
          name: "TUK",
          url: "/tuk",
          icon: "icon-note",
        },
        !!isLJKIntegration() && {
          name: "Pemohon SKK",
          url: "/pemohon-skk",
          icon: "fa fa-link",
        },
        {
          name: "Assessors",
          url: "/Assessors",
          icon: "fa fa-user-circle-o",
        },
        {
          name: "Participant",
          url: "/asesi",
          icon: "fa fa-user-circle-o",
        },
        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat",
        // },
        {
          name: "Skema Sertifikasi",
          url: "/schema/sub-schema",
          icon: "fa icon-grid",
        },

        // {
        //   name: "Schema Certification",
        //   icon: "fa icon-grid",
        //   children: [
        //     {
        //       name: "Main Schema",
        //       url: "/schema/main-schema",
        //       icon: "fa icon-grid",
        //     },
        //     {
        //       name: "Sub Schema",
        //       url: "/schema/sub-schema",
        //       icon: "fa fa-th",
        //     },
        //     {
        //       name: "Unit Competention",
        //       url: "/schema/unit-competention",
        //       icon: "fa fa-th"
        //     }
        //   ],
        // },
        {
          name: "Assessment",
          icon: "icon-calendar",
          children: [
            {
              name: "Submission",
              url: "/assessments/submission",
              icon: "icon-calendar",
            },
            {
              name: "Rejected",
              url: "/assessments/rejected",
              icon: "icon-calendar",
            },
            {
              name: "List Assessment",
              url: "/assessments/list",
              icon: "icon-calendar",
            },
            {
              name: "Archives",
              url: "/assessments/archives",
              icon: "icon-calendar",
            },
          ],
        },
        {
          name: "Data base Asesi",
          url: "/alumnis",
          icon: "icon-badge",
        },
      ],
    },
    id: {
      items: [
        {
          name: "Beranda",
          url: "/dashboard",
          icon: "icon-home",
        },
        // {
        //   name: "Profile",
        //   url: "/profile",
        //   icon: "fa fa-user",
        // },
        {
          name: "Pengguna",
          url: "/users",
          icon: "icon-people",
        },
        {
          name: "TUK",
          url: "/tuk",
          icon: "icon-pencil",
        },
        !!isLJKIntegration() && {
          name: "Pemohon SKK",
          url: "/pemohon-skk",
          icon: "fa fa-link",
        },
        {
          name: "Asesor",
          url: "/Assessors",
          icon: "icon-user",
        },
        {
          name: "Pemohon",
          url: "/asesi",
          icon: "icon-note",
        },
        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat",
        // },

        {
          name: "Skema Sertifikasi",
          url: "/schema/sub-schema",
          icon: "fa icon-grid",
        },
        // {
        //   name: "Skema Sertifikasi",
        //   icon: "fa icon-grid",
        //   children: [
        //     {
        //       name: "Skema Utama",
        //       url: "/schema/main-schema",
        //       icon: "fa icon-grid",
        //     },
        //     {
        //       name: "Sub Skema",
        //       url: "/schema/sub-schema",
        //       icon: "fa fa-th",
        //     },
        //     {
        //       name: "Unit Kompetensi",
        //       url: "/schema/unit-competention",
        //       icon: "fa fa-th"
        //     }
        //   ],
        // },
        {
          name: "Jadwal Asesmen",
          icon: "icon-calendar",
          children: [
            {
              name: "Pengajuan",
              url: "/assessments/submission",
              icon: "icon-calendar",
            },
            {
              name: "Dibatalkan",
              url: "/assessments/rejected",
              icon: "icon-calendar",
            },
            {
              name: "Daftar Asesmen",
              url: "/assessments/list",
              icon: "icon-calendar",
            },
            {
              name: "Pengajuan Banding",
              url: "/assessments/appeal",
              icon: "icon-calendar",
            },
            {
              name: "Arsip",
              url: "/assessments/archives",
              icon: "icon-calendar",
            },
          ],
        },
        {
          name: "Data base Asesi",
          url: "/alumnis",
          icon: "icon-badge",
        },
      ],
    },
  },
  ADT: {
    en: {
      items: [
        {
          name: "Home",
          url: "/dashboard",
          icon: "icon-home",
        },
        {
          name: "Users",
          url: "/data-applicant",
          icon: "fa fa-user-circle-o",
        },
        {
          name: "Participant",
          url: "/asesi",
          icon: "icon-note",
        },
        {
          name: "Assessment",
          icon: "icon-calendar",
          children: [
            {
              name: "List Assessment",
              url: "/assessments/admintuk/list",
              icon: "icon-calendar",
            },

            {
              name: "Draft Asesmen",
              url: "/assessments/admintuk/draft_assessment",
              icon: "icon-calendar",
            },
            {
              name: "Asesmen Ditolak",
              url: "/assessments/admintuk/rejected",
              icon: "icon-calendar",
            },
          ],
        },

        {
          name: "Persyaratan",
          icon: "fa icon-layers",
          children: [
            {
              name: "Persyaratan Dasar",
              url: "/portfolios-umum",
              icon: "fa icon-layers",
            },
            {
              name: "Persyaratan Pendaftaran",
              url: "/portfolios",
              icon: "fa icon-layers",
            },
            {
              name: "Materi Uji Kompetensi",
              url: "/materi-uji",
              icon: "fa icon-layers",
            },
            {
              name: "Bukti Administratif",
              url: "/bukti-administratif",
              icon: "fa icon-layers",
            },
          ],
        },
        // {
        //   name: "Keluhan Aplikasi",
        //   url: "/complaint",
        //   icon: "fa fa-exclamation-triangle",
        // },
        // {
        //   name: "Pengelolaan Surat",
        //   url: "/letters",
        //   icon: "fa fa-envelope-open-o",
        // },
      ],
    },
    id: {
      items: [
        {
          name: "Beranda",
          url: "/dashboard",
          icon: "icon-home",
        },
        {
          name: "Pengguna",
          url: "/data-applicant",
          icon: "fa fa-user-circle-o",
        },
        {
          name: "Pemohon",
          url: "/asesi",
          icon: "icon-note",
        },
        {
          name: "Jadwal Asesmen",
          icon: "icon-calendar",
          children: [
            {
              name: "Daftar Asesmen",
              url: "/assessments/admintuk/list",
              icon: "icon-calendar",
            },

            {
              name: "Draft Asesmen",
              url: "/assessments/admintuk/draft_assessment",
              icon: "icon-calendar",
            },
            {
              name: "Asesmen Ditolak",
              url: "/assessments/admintuk/rejected",
              icon: "icon-calendar",
            },
          ],
        },
        {
          name: "Persyaratan",
          icon: "fa icon-layers",
          children: [
            {
              name: "Persyaratan Dasar",
              url: "/portfolios-umum",
              icon: "fa icon-layers",
            },
            {
              name: "Persyaratan Pendaftaran",
              url: "/portfolios",
              icon: "fa icon-layers",
            },
            {
              name: "Materi Uji Kompetensi",
              url: "/materi-uji",
              icon: "fa icon-layers",
            },
            {
              name: "Bukti Administratif",
              url: "/bukti-administratif",
              icon: "fa icon-layers",
            },
          ],
        },
        // {
        //   name: "Keluhan Aplikasi",
        //   url: "/complaint",
        //   icon: "fa fa-exclamation-triangle",
        // },
        // {
        //   name: "Pengelolaan Surat",
        //   url: "/letters",
        //   icon: "fa fa-envelope-open-o",
        // },
      ],
    },
  },
  ACS: {
    en: {
      items: [
        {
          name: "Home",
          url: "/dashboard",
          icon: "icon-calendar",
        },
        {
          name: "Portfolio",
          url: "/Assessors/list-skill",
          icon: "fa fa-briefcase",
        },
        {
          name: "Asesmen History",
          url: "/Assessors/asesmen-history",
          icon: "fa fa-book",
        },
        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat",
        // },
      ],
    },
    id: {
      items: [
        {
          name: "Beranda",
          url: "/dashboard",
          icon: "icon-calendar",
        },
        {
          name: "Portfolio",
          url: "/Assessors/list-skill",
          icon: "fa fa-briefcase",
        },
        {
          name: "Riwayat Asesmen",
          url: "/Assessors/asesmen-history",
          icon: "fa fa-book",
        },
        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat",
        // },
      ],
    },
  },
  APL: {
    en: {
      items: [
        {
          name: "Home",
          url: "/dashboard",
          icon: "icon-calendar",
        },
        // {
        //   name: "Follow Schedule",
        //   url: "/join-assesment",
        //   icon: "fa fa-handshake-o",
        // },
        {
          name: "Pengajuan Skema",
          url: "/join-skema",
          icon: "fa icon-grid",
        },
        {
          name: "Pengajuan Banding",
          url: "/assessments/appeal",
          icon: "fa fa-history",
        },
        {
          name: "Riwayat Asesmen",
          url: "/asesmen-history",
          icon: "fa fa-book",
        },
        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat",
        // },
      ],
    },

    id: {
      items: [
        {
          name: "Beranda",
          url: "/dashboard",
          icon: "icon-calendar",
        },
        // {
        //   name: "Ikuti Jadwal",
        //   url: "/join-assesment",
        //   icon: "fa fa-handshake-o",
        // },
        {
          name: "Pengajuan Skema",
          url: "/join-skema",
          icon: "fa icon-grid",
        },
        {
          name: "Pengajuan Banding",
          url: "/assessments/appeal",
          icon: "fa fa-history",
        },
        {
          name: "Riwayat Asesmen",
          url: "/asesmen-history",
          icon: "fa fa-book",
        },
        // {
        //   name: "Group Chat",
        //   icon: "fa fa-comments",
        //   url: "/chat"
        // },
      ],
    },
  },
};

export const Permission = (bahasa, role) => {
  let currentBahasa = bahasa ? bahasa : 'id'

  let currentRole = role ? role : 'APL'

  let newPermission = permission.role[currentRole][currentBahasa];

  if ((role ==="SUP" || role === "ADM")){
    const newItems = [...newPermission.items];
    const checkIfThere = hasPropertyWithValue(newItems, 'name', 'Setting');
    if (!checkIfThere){
      if(newItems.length < 14){
        if (isLSPABI()){
          newItems.splice(9, 0, {
            name: "Setting",
            icon: "fa fa-cog",
            children: [
              {
                name: "Master Alat Berat",
                url: "/heavy-vehicles",
                icon: "fa fa-university",
              },
              {
                name: "Master Institusi",
                url: "/intitutions",
                icon: "fa fa-wrench",
              },
            ],
          });
        } else {
          newItems.splice(9, 0, {
            name: "Setting",
            icon: "fa fa-cog",
            children: [
              {
                name: "Master Institusi",
                url: "/intitutions",
                icon: "fa fa-wrench",
              },
            ],
          });
        }
        
        newPermission.items = newItems;
      }
    }
     
  }

  // if (role === "ACS" && (isDemonstrate() && isLSPABI() )){
  //   const newItems = [...newPermission.items];
  //   const checkIfThere = hasPropertyWithValue(newItems, 'name', 'Portfolio');
  //   if (!checkIfThere){
  //     if(newItems.length < 2){
  //       newItems.splice(1, 0, {
  //         name: "Portfolio",
  //         url: "/Assessors/list-skill",
  //         icon: "fa fa-briefcase",
  //       });
  //       newPermission.items = newItems;
  //     }
  //   }
  // }

  newPermission = newPermission.items.filter(function(val){ return val; });

  newPermission = { items:newPermission };

  return newPermission;
};
